export const url = "https://example.com";
export const saveUrl = "https://example.com";
export const pageId = "YHwSm9Bu7y9meNQ7KjMl";
export const appId = "7t517NUWwLliMNIbDCiu";
export default {
  url,
  saveUrl,
  pageId,
  appId
};
