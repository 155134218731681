//import Firebase from '../actions/firebase';
import firebase from "firebase";
import Cookie from "js-cookie";
import "@firebase/firestore";
import nock from "nock";
import global from "../vars/variables";
import React from "react";
var firebasemock = require("firebase-mock");
var Reflux = require("reflux");
var ElementActions = require("../actions/ElementActions");
var $ = require("jquery");
var _data;
var _saveUrl;

var ElementStore = Reflux.createStore({
  init: function () {
    this.variables = global;
    this.pageId = this.getCookie("pageId") || "blank";
    this.appId =
      this.getCookie("testing") === "true"
        ? "DJinGtyeQSLi2LmGZJcx"
        : this.getCookie("appId")
          ? this.getCookie("appId")
          : null;
    this.variables.appId = this.appId;
    this.variables.pageId =
      this.getCookie("testing") !== "true"
        ? "YHwSm9Bu7y9meNQ7KjMl"
        : this.pageId;
    this.looadingAnimation = true;
    this.pages = [];
    this.user = {};
    this.listenTo(ElementActions.createElement, this._create);
    this.listenTo(ElementActions.deleteElement, this._delete);
    this.listenTo(ElementActions.save, this.save);
    this.listenTo(ElementActions.saveData, this._updateOrder);
    this.listenTo(ElementActions.getQuery, this.getQuery);
    this.listenTo(ElementActions.loading, this.loading);
    this.listenTo(ElementActions.setDefaultApp, this.setDefaultApp);
    this.listenTo(ElementActions.loading, this.loading);
    this.listenTo(ElementActions.setPages, this.setPages);
    this.listenTo(ElementActions.getUser, this.getUser);
    //this.listenTo(ElementActions.firebaseInit, this.firebaseInit);
    //this.listenTo(ElementActions.getAllPages, this.getAllPages);
    this.firebaseInit(true);
  },
  loadingComp: function () {
    let loadingAnimation = this.loadingAnimation;
    return null;
  },
  getCookie: function (name) {

    return Cookie.get(name);
  },
  setAppId: function (appId) {
    Cookie.set("appId", appId);
    this.appId = appId;
  },
  removeCookie: function (cookieName) {
    Cookie.remove(cookieName);
    this[cookieName] = "";
  },
  setPageId: function (pageId) {
    Cookie.set("pageId", pageId);
    this.pageId = pageId;
  },
  setPages: function (pages) {
    this.pages = pages;
  },
  load: function (urlOrData, saveUrl) {
    var self = this;
    _data = urlOrData.elements || [];
    self.trigger(_data);
  },
  loadPage: async function (id) {
    this.setPageId(id);
    const pageElements = await ElementStore.getQuery("get", "pages", id);
    this.load(pageElements.elements);
  },
  _create: function (element) {
    if (!_data) {
      _data = [];
    }
    _data.push(element);
    this.trigger(_data);
    this.save();
  },

  _delete: function (element) {
    var index = _data.indexOf(element);
    _data.splice(index, 1);
    this.trigger(_data);
    this.save();
  },

  _updateOrder: function (elements) {
    _data = elements;
    this.trigger(_data);
    this.save();
  },

  strip: function (html) {
    if (!html) {
      return "";
    }
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    const stripped = (tmp.textContent || tmp.innerText || "").trim();
    return stripped;
  },

  save: async function () {
    //firebase.database().ref('apps/' + userId+'/').set(_data);
    const page = await this.database.collection("pages").doc(this.pageId);
    (_data || []).map(el => {
      el.content = this.strip(el.content);
      return el;
    });

    //document.querySelector('#previewPanel').postMessage({ type: 'update', key: docId }, '*');

    const setWithMerge = await page.set({ elements: _data }, { merge: true });
  },

  firebaseInit: function (init) {
    if (!init) return;
    let fb = !this.fb ? this.firebase() : this.fb;
    if (!this.fb) this.fb = fb;
    if (
      this.getCookie("testing") !== "true" &&
      (process.env.NODE_ENV === "production" &&
        process.env.NODE_ENV_OVERWRITE !== "stage" &&
        window.location.href.indexOf("app-stage.ux-builder.co.uk") === -1)
    ) {
      this.fireapp = this.fb.initializeApp({
        apiKey: process.env.REACT_APP_APIKEY,
        authDomain: process.env.REACT_APP_PROJECTID + ".firebaseapp.com",
        databaseURL:
          "https://" + process.env.REACT_APP_PROJECTID + ".firebaseio.com",
        projectId: process.env.REACT_APP_PROJECTID,
        storageBucket: "uploads-app-builder",
        messagingSenderId: "365443702640"
      });
    } else {
      var firebaseConfig = {
        apiKey: "AIzaSyDJY0RgPrIOLPPuOaUotV6T-TEsoGMh5Gc",
        authDomain: "app-builder-stage.firebaseapp.com",
        databaseURL: "https://app-builder-stage.firebaseio.com",
        projectId: "app-builder-stage",
        storageBucket: "app-builder-stage.appspot.com",
        messagingSenderId: "899460753560",
        appId: "1:899460753560:web:e6908767a1bf549e0a08ed",
        measurementId: "G-CGBMEC0FJ7"
      };
      // Initialize Firebase
      this.fireapp = fb.initializeApp(firebaseConfig);
    }
    this.database = this.fireapp.firestore();
  },
  firebase: function () {
    return firebase;
  },
  getAllPages: function () {
    return this.getQuery("get", "apps", this.appId).then(response => {
      return (response || {}).pages;
    });
  },
  customGetQueryObj: function (obj) {
    return this.customGetQuery(
      obj.collection,
      obj.where,
      obj.limit,
      obj.orderBy,
      obj.startAt,
      obj.endAt
    );
  },
  customGetQuery: function (collection, where, limit, orderBy, startAt, endAt) {
    let query = this.database.collection(collection);
    if (where) {
      query = query.where(where[0], where[1], where[2]);
    }
    if (orderBy) {
      query = query.orderBy(orderBy, "desc");
    }
    if (startAt) {
      if (!startAt.seconds && startAt.indexOf("n") !== -1) {
        startAt = parseFloat(startAt.replace("n", "")) * 1000;
        startAt = firebase.firestore.Timestamp.fromDate(new Date(startAt));
      }

      query = query.startAfter(startAt);
    }
    if (endAt) {
      if (!endAt.seconds && endAt.indexOf("p") !== -1) {
        endAt = parseFloat(endAt.replace("n", "")) * 1000;
        endAt = firebase.firestore.Timestamp.fromDate(new Date(endAt));
      }
      query = query.endAt(endAt);
    }
    return query
      .limit(limit || 10)
      .get()
      .then(querySnapshot => {
        let results = [];
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          results.push({ id: doc.id, data: doc.data() });
        });
        return results;
      });
  },
  getQuery: function (type, collection, docId, elObj, limit) {
    if (type === "get") {
      if (docId) {
        return this.database
          .collection(collection)
          .doc(docId)
          .get()
          .then(querySnapshot => {
            return querySnapshot.data();
          });
      } else {
        return this.database
          .collection(collection)
          .limit(limit || 10)
          .get()
          .then(querySnapshot => {
            let docs = [];
            querySnapshot.forEach(doc => {
              docs.push(doc.data());
            });
            return docs;
          });
      }
    }
    if (type === "update") {
      console.log("Update");
      let doc = this.database.collection(collection).doc(docId);
      doc.set(elObj, { merge: true });
    }
    if (type === "insert") {
      console.log("Inserting");
      return this.database.collection(collection).add(elObj);
    }
    if (type === "delete") {
      console.log("Deleting");
      return this.database
        .collection(collection)
        .doc(docId)
        .delete();
    }
  },
  loading: function (action) {
    const event =
      action === "show" ? new Event("showLoading") : new Event("hideLoading");
    document.dispatchEvent(event);
  },
  getUser: function () {
    return this.user;
  },
  getUserData: function (user) {
    user = user || {};
    var displayName = user.displayName || "test";
    var email = user.email || "test";
    var emailVerified = user.emailVerified || "test";
    var photoURL = user.photoURL || "test";
    var uid = user.uid || "test";
    var phoneNumber = user.phoneNumber || "test";
    var providerData = user.providerData || "test";
    var userData = {
      displayName: displayName,
      email: email,
      emailVerified: emailVerified,
      phoneNumber: phoneNumber,
      photoURL: photoURL,
      uid: uid
    };
    this.user = userData;
    return userData;
  },
  getAndSetPageId: async function (pageId) {
    return new Promise(async resolve => {

      if (!this.appId || this.appId === "blank") {
        return;
      }
      const response = await this.getQuery("get", "apps", this.appId);
      pageId = !pageId ? (response.pages.filter(page => page.isDefault == true)[0] || {}).id : pageId;
      if (!pageId) {
        pageId = ((response.pages || [])[0] || {}).id;
      }
      if (pageId) {
        this.setPageId(pageId);
        const loadData = await this.getQuery("get", "pages", pageId);
        this.load(loadData, "");
        resolve(loadData);
      } else {
        resolve({})
      }
    })
  }
});

export default ElementStore;
