var Reflux = require("reflux");

var ElementActions = Reflux.createActions([
  "createElement",
  "editElement",
  "deleteElement",
  "getUser",
  "loading",
  "saveData",
  "save",
  "setDefaultApp",
  "setPages",
  "firebaseInit",
  "getQuery",
  'firebase',
  "firebaseui"
]);

module.exports = ElementActions;
