import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import loadable from '@loadable/component'
import "./assets/css/application.css";
import ElementStore from "./stores/ElementStore";
const Login = loadable(() => import("./components/pages/login"));
const FormBuilder = loadable(() => import("./components/pages/"));
const OnBoardingPage = loadable(() => import("./components/pages/onboarding"));

document.querySelector("#login-wrap").classList.remove('login-animated-background');
document.querySelector("#login-wrap").innerHTML = "";
const variables = ElementStore.variables;
const fb = ElementStore.fb;
if (ElementStore.getCookie("testing") === "true") {
  initLoggedInView({});
} else {
  fb.auth().onAuthStateChanged(
    function (user) {
      if (user) initLoggedInView(user);
      else initLoggedOutView(user);
    },
    function (error) {
      console.log(error);
    }
  );
}

function initLoggedOutView(user) {
  if (
    window.location.href.indexOf("start") > -1 &&
    !ElementStore.getCookie("getstarted")
  ) {
    ReactDOM.render(
      <div>
        <OnBoardingPage variables={variables} user={user} />
      </div>,
      document.getElementById("login-wrap")
    );
  } else {
    ReactDOM.render(
      <Login variables={variables} user={user} successUrl="/#/" />,
      document.getElementById("login-wrap")
    );
  }
}

function initLoggedInView(user) {
  window.console.log("======", user);
  if (user || ElementStore.getCookie("testing") === "true") {
    user = user || {};
    const userData = ElementStore.getUserData(user);
    if (window.FS && window.FS.identify) {
      window.FS.identify(userData.id, {
        displayName: userData.displayName,
        email: userData.email,
        reviewsWritten_int: 14
      });
    }
    if (ElementStore.getCookie("testing") === "true") {
      ReactDOM.render(
        <FormBuilder variables={variables} />,
        document.getElementById("form-builder")
      );
    } else {
      window.console.log("----------------");
      user.getIdToken().then(function (accessToken) {
        userData.accessToken = accessToken || "test";

        ReactDOM.render(
          <FormBuilder variables={variables} userData={userData} />,
          document.getElementById("form-builder")
        );
      });
    }
  }
}
